<template>
    <div class="wrapper--full">
        <div class="pagenotfound">
            <div class="pagenotfound__content">
                <img src="../assets/img/404.svg" alt="404"/>
                <h1>Sorry, the page you're looking for cannot be found</h1>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "http404",
        props: ['modifier'],
    }
</script>

<style lang="scss" scoped>
.pagenotfound {
    min-height: 30rem;
}
</style>
