    <template>
        <div v-if="isError">
            <seo-meta></seo-meta>
            <div class="quicksearch-overlay" v-on:click="closeOverlay()"  v-if="this.$store.state.quickSearchOverlay"></div>
            <main-header></main-header>
            <http404 />

            <!-- Show suggested ads -->
            <div class="wrapper wrapper--full wrapper--grey">
                <div class="wrapper">
                    <div class="grid grid--usedcars">
                        <div class="usedcars_flex_item usedcars_flex_item--right">
                            <h2 class="pt-1" v-if="showListing">We found similar ads for {{make404}} {{model404}} that you might be interested in.</h2>
                            <car-listings v-show="showListing" :slug="mySlug"></car-listings>
                            <router-link v-if="showListing" :to="{path: mySlug}">Check out more ads for {{make404}} {{model404}}</router-link>
                            <quick-search v-if="!showListing" :monthlyPrice="false"></quick-search>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div v-else>
        <div class="printable">
            <div class="printable__top">
                <div class="printable__top_left">
                    <dl>
                        <dt v-if="car">{{ car.reg_year }} {{ car.make }} {{ car.model }}</dt>
                        <dd v-if="car && car.variant && car.variant && car.variant.length < 80">{{ car.variant }}</dd>
                        <dd v-else-if="car && car.variant && car.variant.length > 80">{{ car.variant.substring(0,80) + "..." }}</dd>
                    </dl>
                </div>
                <div class="printable__top_right">
                    <dl>
                        <dt>&euro;{{ formattedCarPrice }}</dt>
                        <dd v-if="formattedMonthlyPrice != 'n/a'">from {{ formattedMonthlyPrice }} pm *</dd>
                    </dl>
                </div>
            </div>
            <div class="printable__images">
                <div class="printable__images_left">
                    <img :src="image1url" alt=""/>
                </div>
                <div class="printable__images_right">
                    <img :src="image2url" alt=""/>
                    <img :src="image3url" alt=""/>
                    <img :src="image4url" alt=""/>
                </div>
            </div>
            <div class="printable__stats">
                <div class="printable__stats_col">
                    <ul>
                        <li v-if="car">Make: <span class="desc">{{ this.car.make }}</span></li>
                        <li v-if="car">Model: <span class="desc">{{ this.car.model }}</span></li>
                        <li v-if="car">Year: <span class="desc">{{this.car.reg_year }}</span></li>
                        <li v-if="car">Price: <span class="desc">&euro;{{ formattedCarPrice }}</span></li>
                    </ul>
                </div>
                <div class="printable__stats_col">
                    <ul>
                        <li v-if="car">Owners: <span class="desc">{{ formattedOwners }}</span></li>
                        <li v-if="car">Transmission: <span class="desc">{{ formattedTransmissonType }}</span></li>
                        <li v-if="car">Engine Power: <span class="desc">{{ formattedEnginePower }}</span></li>
                        <!-- <li v-if="car">Fuel Economy: <span class="desc">{{ formattedFuelEconCombined }}</span></li> -->
                    </ul>
                </div>
                <div class="printable__stats_col">
                    <ul>
                        <li v-if="car">Odometer: <span class="desc">{{ formattedMileage }} mi</span></li>
                        <li v-if="car">Fuel Type: <span class="desc">{{ formattedFuelType }}</span></li>
                        <li v-if="car">Engine Size: <span class="desc">{{ formattedEngineSize }}</span></li>
                        <li v-if="car">Colour: <span class="desc">{{ formattedColours }}</span></li>
                    </ul>
                </div>
                <div class="printable__stats_col">
                    <ul>
                        <li>Road Tax: <span class="desc">{{ formattedRoadTax }}</span></li>
                        <li>NCT Due: <span class="desc">{{ formattedNCTDue }}</span></li>
                        <!-- <li>Safety Rating:
                            <span class="desc">
                                <div class="stars stars--small">
                                    <span v-for="(star,idx) in ncapOverall" v-bind:key="idx+150"></span>
                                    <span class="stars__empty" v-for="(star, idx) in 5-ncapOverall" v-bind:key="idx+160"></span>
                                </div>
                            </span>
                        </li> -->
                        <!-- <li>Environment:
                            <span class="desc">
                                <div class="stars stars--small stars--green" v-if="!checkDisabled( environmental )">
                                    <span v-for="(star, idx) in environmental" v-bind:key="idx+170"></span>
                                    <span class="stars__empty" v-for="(star, idx) in 5-environmental" v-bind:key="idx+180"></span>
                                </div>
                            </span>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="printable__info" v-if="car">
                <h1 v-if="car">
                    {{ formattedFullInfo }}
                </h1>
                <h2 v-if="car && car.features && car.features.length > 0">Features:</h2>
                <p v-if="car"><span v-if="car.description">{{ car.description.replace(/\.$/, '').trim()}}.</span>  <span v-if="this.getCarFeatures().length > 0">{{this.getCarFeatures()}}.</span></p>
            </div>
            <div class="print-spacer">
            </div>
            <div class="printable__dealer" v-if="car">
                <h3>{{ getDealerAdvertiserHeader }}</h3>
                <div class="printable__dealer_content">
                    <div class="printable__dealer_left">

                        <div class="detail-col">
                            <div class="display-name">
                                <h4>{{ getDisplayName }}</h4>
                                <div class="stars stars--med" v-if="true==false">
                                    <span v-for="(star,idx) in numberStars" v-bind:key="idx+1000"></span>
                                    <span class="stars__empty" v-for="(star,idx) in (5-numberStars)"
                                          v-bind:key="idx+2000"></span>
                                </div>
                                <small v-if="true==false">{{ formattedAverageRating }}/5</small>
                                <div v-if="car && car.status != false && car.dealer.id != 1">
                                    <address>
                                        {{ formattedAddress1 }}<br/>
                                        {{ formattedAddress2 }}<br/>
                                        {{ formattedAddress3 }}
                                        {{ formattedAddress4 }}
                                        {{ formattedAddress5 }}<br v-if="formattedAddress5">
                                        {{ formattedCounty }}<br v-if="formattedCounty">
                                        {{ formattedPostcode }}<br v-if="formattedPostcode">
                                    </address>
                                    <div class="printable__dealer_phone"><a>{{ formattedPhone }}</a></div>
                                </div>
                                <div class="location-outer" v-else>
                                    <div class="label">
                                        Location:
                                    </div>
                                    <div class="label address">
                                        <address>
                                            {{ formattedAddress5 }}<br v-if="formattedAddress5">
                                            {{ formattedCounty }}<br v-if="formattedCounty">
                                            {{ formattedPostcode }}<br v-if="formattedPostcode">
                                        </address>
                                    </div>
                                </div>
                                <div class="printable__dealer_phone" v-if="showPhone">Phone: <a>{{ formattedPhone }}</a></div>
                            </div>
                        </div>

                        <div class="detail-col" v-if="car.status != false && car.dealer.id != 1">
                            <div class="opening-hours">
                                <h5>Opening Hours</h5>
                                <ul>
                                    <li>Monday: <span>{{ hoursMonday }}</span></li>
                                    <li>Tuesday: <span>{{ hoursTuesday }}</span></li>
                                    <li>Wednesday: <span>{{ hoursWednesday }}</span></li>
                                    <li>Thursday: <span>{{ hoursThursday }}</span></li>
                                    <li>Friday: <span>{{hoursFriday }}</span></li>
                                    <li>Saturday: <span>{{ hoursSaturday }}</span></li>
                                    <li>Sunday: <span>{{ hoursSunday }}</span></li>
                                </ul>
                            </div>
                        </div>

                        <div class="detail-col">
                            <div class="map" id="print-map"></div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="printable__footer">
                <p>Every effort has been made to ensure the accuracy of the information above, however, errors may occur. Do
                    not rely entirely on this information but confirm with the {{ getDisclaimer }} about items which may affect your
                    decision to purchase.</p>
                <p v-if="formattedMonthlyPrice != 'n/a'">* Terms and conditions apply. Finance is subject to approval.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import MainHeader from "@/components/MainHeader.vue";
    import SeoMeta from "@/components/partials/SeoMeta.vue";
    import http404 from "@/components/http404.vue";
    import CarListings from "@/components/partials/CarListings.vue";
    import QuickSearch from "@/components/QuickSearch.vue";

    var moment = require('moment');
    var numeral = require('numeral');

    export default {
        name: "AdDetailPrintable",
        components: {QuickSearch, CarListings, http404, SeoMeta, MainHeader},

        props: {
            slug: {
                type: String,
                required: true,
            }
        },

        data: function () {
            return {
                adSlug: null,
                hoursMonday: null,
                hoursTuesday: null,
                hoursWednesday: null,
                hoursThursday: null,
                hoursFriday: null,
                hoursSaturday: null,
                hoursSunday: null,
                mapsUrl: "https://maps.googleapis.com/maps/api/js?key=" + process.env.VUE_APP_GOOGLE_API_KEY + "&callback=initMap",
                title: "Used Cars, Cars in Ireland, Electric Cars, 7 Seaters, SUV’s | Terrific.ie",
                description: "Terrific.ie details used cars for sale in Ireland including electric cars, 7 seaters and SUV’s.",
                keywords: "Used Cars, Cars for Sale, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters,  Land Rover, People Carriers",
                robots: "noindex"
            }
        },
        computed: {
            isError(){
                return this.$store.state.ads.error404;
            },
            showPhone() {
                return this.car && this.car.adv_settings ? this.car.adv_settings.show_phone == 1 : false;
            },
            getDisclaimer(){
                if(this.car && this.car.status==false){
                    return '';
                }
                return this.car && this.car.dealer.id != 1 ? 'dealer' : 'seller';
            },
            getDisplayName(){
                let res = '';

                if(this.car && this.car.adv_settings && this.car.dealer.id == 1) {
                        if(this.car.adv_settings.show_name){
                            res = this.car.adv_settings.contact_name
                        }else{
                            res = 'Private Advertiser'
                        }
                }else{
                    if(this.car.status != false){
                        res = this.car.dealer.display_name;
                    }else{
                        res = '';
                    }
                }

                return res;
            },
            getDealerAdvertiserHeader(){
              return this.car && this.car.status != false && this.car.dealer.id != 1 ? 'Dealer Details' : 'Private Advertiser';
            },
            mapCenter() {
                if (this.car != null && this.car.dealer != null && this.car.dealer.id != 1 && this.car.dealer.address.map_lat != null && this.car.dealer.address.map_long != null) {
                    return {
                        lat: parseFloat(this.car.dealer.address.map_lat),
                        lng: parseFloat(this.car.dealer.address.map_long)
                    };
                } else {
                    return {lat: 0, lng: 0};
                }
            },
            zoom() {
                return this.car && this.car.dealer != null && this.car.dealer.address.map_zoom ? parseInt(this.car.dealer.address.map_zoom) : 0;
            },
            car() {
                return this.$store.state.ads.ad[this.adSlug];
            },

            getLink() {
                return 'https://www.terrific.ie/ad-detail-printable/makes-' + this.stringToSlug(this.car.make) + '/models-' + this.stringToSlug(this.car.model) + '/r/' + this.slug;
            },

            starFull() {
                return  require('../assets/img/star-full.svg');
            },

            image1url(){
                if(!this.car || this.car.status == false){
                    return '';
                }else{
                    return this.car != null && this.car.images.length > 0 && this.car.images[0].primaryURL.xl != null ?
                        this.car.images[0].primaryURL.xl : require('../assets/img/photos/missing/large.jpg');
                }
            },

            image2url() {
                if(!this.car || this.car.status == false){
                    return '';
                }else{
                    return this.car != null && this.car.images.length > 1 && this.car.images[1].primaryURL.large != null ?
                        this.car.images[1].primaryURL.large : require('../assets/img/photos/missing/medium.jpg');
                }

            },

            image3url() {
                if(!this.car || this.car.status == false){
                    return '';
                }else{
                    return this.car != null && this.car.images.length > 2 && this.car.images[2].primaryURL.large != null ?
                        this.car.images[2].primaryURL.large : require('../assets/img/photos/missing/medium.jpg');
                }

            },

            image4url() {
                if(!this.car || this.car.status == false){
                    return '';
                }else{
                    return this.car != null && this.car.images.length > 3 && this.car.images[3].primaryURL.large != null ?
                        this.car.images[3].primaryURL.large : require('../assets/img/photos/missing/medium.jpg');
                }

            },

            logoUrl() {
                return require('../assets/img/print-logo.svg');
            },

            formattedCarPrice() {
                return this.car != null && this.car.price != null ? numeral(this.car.price).format('0,0') : '';
            },

            formattedMonthlyPrice() {
                return this.car != null && this.car.from_monthly != null ? '€' + numeral(this.car.from_monthly).format('0') : 'n/a';
            },

            formattedEnginePower() {
                return this.car.max_power_bhp ? numeral(this.car.max_power_bhp).format('0') + ' BHP' : 'Not available';
            },

            formattedEngineSize() {
                return this.car.engine_cc != null ? numeral(this.car.engine_cc / 1000).format('0.0') : 'Not available';
            },

            formattedFuelEconCombined() {
                let mpg = this.car != null && this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_combined != null ? numeral(this.car.ad_extra_info.fuel_cons_combined).format('0.0') + ' mpg' : '';
                if (mpg == '') {
                    return 'Not available';
                }
                return mpg;
            },

            formattedOwners(){
                return this.car.owners != null ? this.car.owners : 'Not available';
            },

            formattedFuelType(){
                return this.car.fuel_type != null ? this.car.fuel_type : 'Not available';
            },

            formattedTransmissonType() {
                return this.car.transmission_type != null ? this.car.transmission_type : 'Not available';
            },

            formattedColours(){
                return this.car.colour != null ? this.car.colour : 'Not available';
            },

            formattedMileage() {
                return numeral(this.car.mileage).format('0,0');
            },

            formattedRoadTax() {
                return this.car != null && this.car.tax_due_date != null && this.car.tax_due_date ? moment(this.car.tax_due_date).format('DD/MM/YYYY') : 'Not available';
            },

            formattedNCTDue() {
                return this.car != null && this.car.test_due_date != null ? moment(this.car.test_due_date).format('DD/MM/YYYY') : 'Not available';
            },

            ncapOverall() {
                return this.car != null && this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_overall != null ? Math.ceil(this.car.ad_extra_info.ncap_overall) : 0;
            },

            environmental() {
                return this.car != null && this.car.ad_extra_info != null && this.car.ad_extra_info.co2_stars != null ? Math.ceil(this.car.ad_extra_info.co2_stars) : 0;
            },

            formattedFullInfo(){
                return this.car.make + ' ' + this.car.model + ' ' + this.car.variant + ' (' + this.car.reg_year + ' ) ';
            },

            formattedAddress1() {
                if(this.car.dealer.id==1){
                     return '';
                }else{
                    return this.car.dealer != null && this.car.dealer.address.address1 != null ? this.car.dealer.address.address1 : '';
                }
            },

            formattedAddress2() {
                if(this.car.dealer.id==1){
                    return "";
                }else{
                    return this.car.dealer != null && this.car.dealer.address.address2 != null ? this.car.dealer.address.address2 : '';
                }
            },

            formattedAddress3() {
                if(this.car.dealer.id == 1){
                    return "";
                }else{
                    return this.car.dealer != null && this.car.dealer.address.address3 != null ? this.car.dealer.address.address3 : '';
                }
            },

            formattedAddress4() {
                if(this.car.dealer.id == 1) {
                    return "";
                }else{
                    return this.car.dealer != null && this.car.dealer.address.address4 != null ? this.car.dealer.address.address4 : '';
                }
            },

            formattedAddress5() {
                if(this.car.status == false){
                    return '';
                }

                if(this.car.dealer.id == 1) {
                    return "";
                }else {
                    return this.car.dealer != null && this.car.dealer.address.address5 != null ? this.car.dealer.address.address5 : '';
                }
            },

            formattedCounty() {
                if(this.car.status == false){
                    return '';
                }
                if(this.car.dealer.id ==  1) {
                    return this.car.location && this.car.location.region ? 'Co. '  + this.car.location.region : '';
                }else {
                    return this.car.dealer != null && this.car.dealer.region != null ? 'Co. ' + this.car.dealer.region : '';
                }
            },

            formattedPostcode() {
                if(this.car.status == false){
                    return '';
                }
                if(this.car.dealer.id == 1) {
                    return "";
                }else {
                    return this.car.dealer != null && this.car.dealer.address.postcode != null ? this.car.dealer.address.postcode : '';
                }
            },

            formattedPhone() {
                if(this.car.status == false){
                    return '';
                }
                if(this.car.dealer.id == 1) {
                    if(this.car.adv_settings && this.car.adv_settings.show_phone==1){
                        return this.car.adv_settings && this.car.adv_settings.phone ? this.car.adv_settings.phone : '';
                    }else{
                        return '';
                    }

                }else {
                    return this.car.dealer != null && this.car.dealer.phone != null ? this.car.dealer.phone : '';
                }
            },

            formattedWeb() {
                if(this.car.status == false){
                    return '';
                }
                if(this.car.dealer.id == 1) {
                    return "";
                }else {
                    return this.car.dealer != null && this.car.dealer.web != null ? this.car.dealer.web : '';
                }
            },

            formattedAverageRating() {
                return this.car && this.car.status != false && this.car.dealer != null && this.car.dealer.average_rating != null ? numeral(this.car.dealer.average_rating).format('0.00') : 0;
            },

            numberStars() {
                return this.car && this.car.status != false && this.car.dealer != null && this.car.dealer.average_rating != null ? Math.round(this.car.dealer.average_rating) : 0;
            },
        },

        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            this.getAd();
        },

        mounted() {
            if (!this.car) {
                this.getAd();
            } else {
                // Google Maps
                if (this.checkGoogleMapsTag() == false) {
                    this.injectGoogleMaps();
                    this.callGmaps();
                } else {
                    this.callGmaps();
                }
            }
        },

        methods: {
            getCarFeatures(){
                if(this.car.status == false){
                    return false;
                }
                let str = '';
                str = this.car.features.map( v => v.description).join(', ');
                if(str.length > 300){
                    return str.slice(0,300).replace(/,[^,]+$/, "");
                }else{
                    return str;
                }
            },
            async getAd() {
                this.adSlug = this.slug.split("/").pop();

                if(this.$route.query.deposit !== 'undefined' && this.$route.query.deposit > 0){
                    this.adSlug += '?deposit=' + this.$route.query.deposit;
                }

                this.isLoading = true;
                await this.$store.dispatch('ads/fetchAd', {slug: this.adSlug, router: this.$router});
                this.isLoading = false;

                if(this.car.status==false){
                    return this.$router.push('/not-found');
                }

                this.makeHours();

                if (typeof window !== 'undefined') {
                    // Google Maps
                    if (this.checkGoogleMapsTag() == false) {
                        this.injectGoogleMaps();
                        this.callGmaps();
                    } else {
                        this.callGmaps();
                    }
                }
            },
            injectGoogleMaps() {
                // Create the script tag, set the appropriate attributes
                let script = document.createElement('script');
                script.src = this.mapsUrl;
                script.defer = true;
                script.async = true;
                // Append the 'script' element to 'head'
                document.head.appendChild(script);
            },
            makeHours() {
                if (this.car && this.car.status!=false) {
                    let index;
                    let element;
                    let hours;

                    for (index = 0; index < this.car.dealer.hours.length; index++) {
                        element = this.car.dealer.hours[index];
                        if (element.optional_text != "") {
                            hours = element.optional_text;
                        } else if (element.open == null || element.open == ':' || element.closed == null || element.closed == ':') {
                            hours = 'Closed';
                        } else {
                            hours = element.open + ' to ' + element.closed;
                        }

                        if(hours == 'Flexible viewings by appointment'){
                            hours = 'By appointment';
                        }
                        switch (element.dow) {
                            case 1:
                                this.hoursMonday = hours;
                                break;
                            case 2:
                                this.hoursTuesday = hours;
                                break;
                            case 3:
                                this.hoursWednesday = hours;
                                break;
                            case 4:
                                this.hoursThursday = hours;
                                break;
                            case 5:
                                this.hoursFriday = hours;
                                break;
                            case 6:
                                this.hoursSaturday = hours;
                                break;
                            case 7:
                                this.hoursSunday = hours;
                                break;
                        }
                    }
                }
            },

            stringToSlug(str) {
                str = str.replace(/^\s+|\s+$/g, ''); // trim
                str = str.toLowerCase();

                // remove accents, swap ñ for n, etc
                let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
                let to = "aaaaeeeeiiiioooouuuunc------";
                for (let i = 0, l = from.length; i < l; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                    .replace(/\s+/g, '_') // collapse whitespace and replace by -
                    .replace(/-+/g, '_'); // collapse dashes

                return str;
            },
            callGmaps() {
                if(!this.car || this.car.status==false){
                    return false;
                }
                if(this.car && this.car.dealer.id==1 ){
                    return false;
                }
                // Attach your callback function to the `window` object
                let self = this;
                // let infoWindow;
                window.initMap = function () {
                    // JS API is loaded and available
                    window.map = new window.google.maps.Map(document.getElementById("print-map"), {
                        center: self.mapCenter,
                        zoom: self.zoom
                    });

                    new window.google.maps.Marker({
                        position: self.mapCenter,
                        map: window.map,
                        title: self.formattedDisplayName
                    });
                };

                if (typeof window.google !== 'undefined') {

                    window.map = new window.google.maps.Map(document.getElementById("print-map"), {
                        center: self.mapCenter,
                        zoom: self.zoom
                    });

                    new window.google.maps.Marker({
                        position: self.mapCenter,
                        map: window.map,
                        title: self.formattedDisplayName
                    });

                    window.google.maps.event.addListenerOnce(window.map, 'tilesloaded', function () {
                        window.print();
                    });
                }
            },
            checkGoogleMapsTag() {
                var scripts = document.getElementsByTagName('script');
                for (var i = scripts.length; i--;) {
                    if (scripts[i].src == this.mapsUrl) return true;
                }
                return false;
            },
            checkDisabled(val) {
                return val == 'Not available';
            },
        }
    }
</script>

<style scoped>

</style>
