<template>
    <div class="modal main-hirepurchase-modal" style="display:flex">

        <div class="modalform modalhirepurchase">
            <div class="modal__wrapper">
                <div class="modal__heading" @click="closeModal">
                    <h1>Apply for Finance</h1>
                </div>

                <div class="modalhirepurchase__wrapper" v-if="car.from_monthly != null">
                    <div class="modalhirepurchase__left">
                        <h1>Your vehicle summary</h1>
                        <h2>{{ this.car.reg_year }} {{ this.car.make }} {{ this.car.model }}</h2>
                        <h3>{{ this.car.variant }}</h3>
                        <img v-if="this.car && this.car.images.length > 0" :src="this.car.images[0].primaryURL.large" alt="" />
                    </div>
                    <div class="modalhirepurchase__middle">
                        <div class="apply-for-finance-heading">
                            <div class="column icon">
                            </div>
                            <div class="column heading">
                                <h4>Apply for Finance</h4>
                            </div>
                        </div>
                        <p>Pay any deposit and fees, and then the monthly repayments to own the vehicle at the end of the agreement.</p>

                        <div class="slider-container">
                            <div class="slider-depoist">
                                <div class="label-container">
                                    <div class="label">Deposit</div>
                                    <div class="slide-max-val">€ {{ formattedDeposit }}</div>
                                </div>
                                <range-slider
                                    class="deposit-sld"
                                    min="0"
                                    :max="getMaxDeposit()"
                                    step="1"
                                    @input="updateSliderDepositLabel"
                                    @change="updateSliderDepositValue"
                                    :value="sliderDepositValue"
                                >
                                </range-slider>
                            </div>
                            <div class="slider-term">
                                <div class="label-container">
                                    <div class="label">Term</div>
                                    <div class="slide-max-val">{{ sliderTermValue }} Months</div>
                                </div>
                                    <range-slider
                                        v-if="this.termValues['min'] != this.termValues['max']"
                                        class="term-sld"
                                        :min="this.termValues['min']"
                                        :max="this.termValues['max']"
                                        :step="this.termValues['step']"
                                        v-model="sliderTermValue"
                                        @change="updateSliderTermValue"
                                    >
                                    </range-slider>
                            </div>
                        </div>

                        <dl>
                            <dd>{{ formattedMonthlyPrice }} / month</dd>
                        </dl>
                        <div class="actionbutton actionbutton--blue">
                            <router-link :to="{path: getActionLink()}">
                                <div class="actionbutton__icon actionbutton__icon--finance"></div>
                                <div class="actionbutton__text">Apply for Finance</div>
                            </router-link>
                        </div>
                    </div>
                    <div class="modalhirepurchase__right">
                        <div class="loading-panel" id="loading-panel">
                            <clip-loader class="custom-class" :color="color" :loading="loading" :size="size"></clip-loader>
                        </div>
                        <ul v-if="!this.loading">
                            <li>Finance Type <span>Hire Purchase</span></li>
                            <li>No. of payments (monthly) <span>{{ this.car.number_of_payments }}</span></li>
                            <li>Contract Length <span>{{ this.car.number_of_payments }} months</span></li>
                            <li>Cash Price <span>&euro;{{ formattedCarPrice }}</span></li>
                            <li>Customer Deposit <span>&euro;{{ formattedCustomerDeposit }}</span></li>
                            <li>Total amount of credit <span>&euro;{{ formattedLoanAmount }}</span></li>
                            <li>Total amount payable <span>&euro;{{ formattedAmountPayable }}</span></li>
                            <li>Representative APR <span>{{ formattedApr }}%</span></li>
                            <li>Charge for Credit <span>&euro;{{ formattedCostOfCredit }}</span></li>
                            <li>Documentation Fee <span>&euro;{{ formattedDocumentationFee }}</span></li>
                            <li>Completion Fee <span>&euro;{{ formattedCompletionFee }}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modalhirepurchase__wrapper" v-if="car.from_monthly == null">
                <h1>No finance available on this vehicle</h1>
            </div>
        </div>
    </div>
</template>

<script>
    var numeral = require('numeral');
    import EventBus from "../../event-bus";
    import RangeSlider from 'vue-range-slider';
    import { ClipLoader } from '@saeris/vue-spinners'

    export default {
        name: "ModalHirePurchase",
        props: ['car'],

        components: {
            RangeSlider,
            ClipLoader
        },
        data () {
            return {
                url: process.env.VUE_APP_API_URL + '/api/finance/calculate',
                sliderDepositValue: 0,
                sliderDepositValueLabel:0,
                sliderTermValue: this.car.number_of_payments || 60,
                errors: null,
                size: 45,
                color: '#30AABC',
                loading: false
            }
        },
        computed: {
            termValues() {
                let arr = JSON.parse(JSON.stringify(this.car.term_values));
                return {
                    min: Math.min(... arr),
                    max: Math.max(... arr),
                    step : 12
                };
            },
            formattedMonthlyPrice() {
                return this.car.from_monthly != null ? '€' + numeral(this.car.from_monthly).format('0,0') : '';
            },

            formattedCarPrice() {
                return numeral(this.car.price).format('0,0');
            },

            formattedCustomerDeposit() {
                return numeral(this.car.deposit).format('0,0');
            },

            formattedLoanAmount() {
                return numeral(this.car.loan_amount).format('0,0');
            },

            formattedAmountPayable() {
                return numeral(parseInt(this.car.cost_of_credit) + parseInt(this.car.loan_amount)).format('0,0');
            },

            formattedCostOfCredit() {
                return numeral(this.car.cost_of_credit).format('0,0');
            },

            formattedApr() {
                return numeral(this.car.apr).format('0.00');
            },

            formattedDocumentationFee() {
                return numeral(this.car.documentation_fee).format('0,0');
            },
            formattedCompletionFee() {
                return numeral(this.car.completion_fee).format('0,0');
            },
            formattedDeposit(){
                return numeral(this.sliderDepositValueLabel).format('0,000');
            },
        },

        methods: {
            getActionLink(){
                let link = '/dealer/' + this.stringToSlug(this.car.dealer.display_name) + '/finance/' + this.car.id
                if(this.sliderDepositValueLabel){
                    link += '/' + this.sliderDepositValueLabel;
                }
                return link;
            },
            updateSliderDepositLabel (event) {
                this.sliderDepositValueLabel = this.car.deposit_values[event];
            },
            updateSliderDepositValue (event) {
                this.sliderDepositValueLabel = this.car.deposit_values[event];
                this.calculateFinance();
            },
            updateSliderTermValue () {
                this.calculateFinance();
            },
            showLoading(){
                this.loading = true;
            },
            hideLoading(){
                this.loading = false;
            },
            calculateFinance(){

                let deposit = this.sliderDepositValueLabel;
                let term = this.sliderTermValue;

                this.showLoading();
                this.axios.post(this.url, {
                    'adId': this.car.id,
                    'deposit': deposit,
                    'term' : term
                })
                    .then(response => {
                        // EventBus.$emit('unsetIsLoading');
                        this.car.from_monthly = response.data.monthly_payments;
                        this.car.deposit = response.data.deposit_and_trade_in;
                        this.car.cost_of_credit = response.data.total_cost_of_credit;
                        this.car.loan_amount = response.data.loan_amount;
                        this.car.apr = response.data.apr;
                        this.car.irr = response.data.irr;
                        this.car.documentation_fee = response.data.documentation_fee;
                        this.car.completion_fee = response.data.completion_fee;
                        this.car.price = response.data.price;
                        this.car.number_of_payments = response.data.term;
                        this.hideLoading();
                    })
                    .catch(error => {
                        // EventBus.$emit('unsetIsLoading');
                        // this.errors.record(error.response.data);
                        console.error("API Error", error);
                    });
            },
            closeModal() {
                EventBus.$emit('closeModal');
            },
            getMaxDeposit(){
                return this.car.deposit_values.length - 1;
            },
            stringToSlug (str) {
                str = str.replace(/^\s+|\s+$/g, ''); // trim
                str = str.toLowerCase();

                // remove accents, swap ñ for n, etc
                let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
                let to   = "aaaaeeeeiiiioooouuuunc------";
                for (let i=0, l=from.length ; i<l ; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                    .replace(/\s+/g, '-') // collapse whitespace and replace by -
                    .replace(/-+/g, '-'); // collapse dashes

                return str;
            },
            findKeyByValue(value){
                this.sliderDepositValue = Object.keys(this.car.deposit_values).find(key => this.car.deposit_values[key] === value);
            }
        },
        mounted(){
            this.sliderDepositValueLabel = this.car.deposit;
            this.findKeyByValue(this.car.deposit);
        }
    }
</script>

<style scoped>
</style>
