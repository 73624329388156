<template>
    <div v-if="car">
        <div class="floatingbuttons" :class="showPhone == true ? 'floatingbuttons--showphone' : ''">
            <div class="floatingbuttons__phonenumber"><a :href="formattedPhoneNumber">{{ car.dealer.phone }}</a></div>
            <div class="floatingbuttons__wrapper">
                <a v-if="car.dealer.id!= 1 && getCountPhoneNumbers() == 1 || car.adv_settings && car.adv_settings.show_phone ==1 && getCountPhoneNumbers() == 1" :href="formattedPhoneNumber" class="floatingbuttons__button floatingbuttons__button--red floatingbuttons__button--contact"><span>Phone</span></a>
                <a v-if="car.dealer.id!= 1 && getCountPhoneNumbers() > 1 || car.adv_settings && car.adv_settings.show_phone ==1 && getCountPhoneNumbers() > 1" href="javascript:void(0)" class="floatingbuttons__button floatingbuttons__button--red floatingbuttons__button--contact" @click="togglePhoneModal"><span>Phone</span></a>
                <a v-if="car.dealer.id!= 1 || car.adv_settings && car.adv_settings.email_optin ==1" href="javascript:void(0)" class="floatingbuttons__button floatingbuttons__button--green floatingbuttons__button--enquire" @click="openEmailSellerModal"><span>Email</span></a>
                <a v-if="showFinance" href="javascript:void(0)" class="floatingbuttons__button floatingbuttons__button--blue floatingbuttons__button--finance" @click="openHirePurchaseModal"><span>Finance</span></a>
            </div>
        </div>

        <modal-email-seller v-if="modalEmailSellerOpen" :car="car"></modal-email-seller>
        <modal-call-seller v-if="modalCallSellerOpen" :car="car"></modal-call-seller>
        <modal-hire-purchase v-if="modalHirePurchaseOpen" :car="car"></modal-hire-purchase>
    </div>
</template>

<script>
    import ModalEmailSeller from '@/components/partials/ModalEmailSeller'
    import ModalHirePurchase from '@/components/partials/ModalHirePurchase'
    import EventBus from "../../event-bus";
    import ModalCallSeller from "./ModalCallSeller";

    export default {
        name: "FloatingButtonsAdDetail",
        props: ['car'],

        components: {ModalCallSeller, ModalHirePurchase, ModalEmailSeller},

        data: function() {
            return {
                modalEmailSellerOpen: false,
                modalHirePurchaseOpen: false,
                modalCallSellerOpen: false,
                showPhone: false,
            }
        },

        computed: {
            formattedPhoneNumber() {
                if(this.car == null){
                    return false;
                }
                return 'tel:' + this.car.dealer.phone;
            },
            showFinance(){
                return this.car.dealer.show_finance_on_terrific == 1;
            },
        },

        mounted() {
            let that = this;

            EventBus.$on('closeModal', function () {
                that.modalEmailSellerOpen = false;
                that.modalHirePurchaseOpen = false;
                that.modalCallSellerOpen = false;
            });

            EventBus.$on('openHirePurchaseModal', function () {
                that.modalHirePurchaseOpen = true;
            });
        },

        methods: {
            openEmailSellerModal() {
                this.modalEmailSellerOpen = true;
            },
            openHirePurchaseModal() {
                this.modalHirePurchaseOpen = true;
            },
            getCountPhoneNumbers(){
                let sum = 0;
                if(this.car.dealer.mobile != null){
                    sum = 1;
                }
                return this.car.dealer.phone.split('/').length + sum;
            },

            togglePhoneModal() {
                if (window != undefined) {
                    if (window.innerWidth < 1024) {
                        this.modalCallSellerOpen = true;
                    } else {
                        this.showPhone = !this.showPhone;
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
