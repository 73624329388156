<template>
    <div class="ad__body">
        <div class="actionlinks">
            <div class="actionlinks__link actionlinks__link--share">
                <a href="https://www.addtoany.com/share" class="a2a_dd" :data-a2a-url="adShortLink"><span class="actionlinks__link_wrapper"><span class="actionlinks__link_text">Share</span></span></a>
            </div>
            <div class="actionlinks__link" :class="liked ? 'actionlinks__link--liked' : 'actionlinks__link--like'">
                <a href="javascript:void(0)">
                    <span class="actionlinks__link_wrapper" @click="likeAdd(adId)"><span class="actionlinks__link_text">Shortlist</span></span>
                </a>
            </div>
            <div class="actionlinks__link actionlinks__link--print actionlinks__link--desktop">
                <router-link :to="{path: getLink}" target="_blank"><span class="actionlinks__link_wrapper"><span class="actionlinks__link_text">Print</span></span></router-link>
            </div>
            <div class="actionlinks__link actionlinks__link--forward actionlinks__link--desktop">
                <a href="javascript:void(0)" @click="openForwardModal"><span class="actionlinks__link_wrapper"><span class="actionlinks__link_text">Forward</span></span></a>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "../../event-bus";

    export default {
        name: "AdActionLinks",
        props: ['adId', 'make', 'model', 'slug'],

        data: function() {
            return {
                likes: [],
                liked:false
            }
        },

        computed: {
            getLink(){
                let link = '/ad-detail-printable/makes-' + this.stringToSlug(this.make) + '/models-' + this.stringToSlug(this.model) + '/r/' + this.slug;
                return this.getLinkExtraParams(link);
            },
            adShortLink() {
                let link = process.env.VUE_APP_BASE_URL + '/' + this.adId;
                if (typeof window !== 'undefined') {
                    link = window.location.origin + '/' + this.adId;
                }
                return link;
            },
        },

        mounted(){
            this.loadLikes();
            this.checkLickedAd();


        },

        methods: {
            getLinkExtraParams(link){
                if(this.$route.query.deposit !== 'undefined' && this.$route.query.deposit > 0){
                    link = link + '?deposit=' + this.$route.query.deposit;
                }
                return link;
            },
            stringToSlug (str) {
                str = str.replace(/^\s+|\s+$/g, ''); // trim
                str = str.toLowerCase();

                // remove accents, swap ñ for n, etc
                let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
                let to   = "aaaaeeeeiiiioooouuuunc------";
                for (let i=0, l=from.length ; i<l ; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                    .replace(/\s+/g, '+') // collapse whitespace and replace by -
                    .replace(/-+/g, '+'); // collapse dashes

                return str;
            },

            loadLikes(){
                this.likes = localStorage.getItem('likes') != null ? JSON.parse(localStorage.getItem('likes')) : [];
            },

            likeAdd(adId){
                this.loadLikes();

                if(!this.likes.includes(adId)){
                    this.likes.push(adId);
                }else{
                    this.likes = this.likes.filter(element => element != adId);
                }
                localStorage.setItem('likes', JSON.stringify(this.likes));
                this.checkLickedAd();
            },
            checkLickedAd(){
                if(this.likes.includes(this.adId)){
                    this.liked = true;
                }else{
                    this.liked = false;
                }
            },
            openForwardModal() {
                EventBus.$emit('openForwardModal');
            }
        }
    }
</script>

<style scoped>

</style>
