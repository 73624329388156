import { render, staticRenderFns } from "./AdDetailPrintable.vue?vue&type=template&id=04b9fc3d&scoped=true"
import script from "./AdDetailPrintable.vue?vue&type=script&lang=js"
export * from "./AdDetailPrintable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b9fc3d",
  null
  
)

export default component.exports