<template>
    <div class="modal" style="display: flex">

        <div class="modalform">
            <div class="modal__wrapper">
                <div class="modal__heading" @click="closeModal">
                    <h1>Forward to a Friend</h1>
                </div>
                <div class="modalform__form">
                    <form method="post" @submit.prevent="sendEmail" @keydown="errors.clear($event.target.name)">
                        <input type="email" name="email" id="email" placeholder="Your Friend's Email Address*"
                               class="form-control" v-model="email" :class="errors.has('email') ? 'form-control--error' : null"/>

                        <div v-if="errors.has('email')">
                            <div class="form-error"
                                 v-for="(error, index) in errors.get('email')" :key="index"
                                 v-text="error"></div>
                        </div>

                        <input type="text" name="name" id="name" placeholder="Your Name*" class="form-control"
                            v-model="name" :class="errors.has('name') ? 'form-control--error' : null"/>

                        <div v-if="errors.has('name')">
                            <div class="form-error"
                                 v-for="(error, index) in errors.get('name')" :key="index"
                                 v-text="error"></div>
                        </div>

                        <input type="email" name="sender_email" id="sender_email" placeholder="Your Email Address*" class="form-control"
                            v-model="sender_email" :class="errors.has('sender_email') ? 'form-control--error' : null"/>

                        <div v-if="errors.has('sender_email')">
                            <div class="form-error"
                                 v-for="(error, index) in errors.get('sender_email')" :key="index"
                                 v-text="error"></div>
                        </div>

                        <textarea name="message" id="message" :placeholder="placeholder" class="form-control"
                            v-model="message" :class="errors.has('message') ? 'form-control--error' : null"></textarea>

                        <div v-if="errors.has('message')">
                            <div class="form-error"
                                 v-for="(error, index) in errors.get('message')"
                                 :key="index" v-text="error"></div>
                        </div>

                        <input type="submit" value="Send" :disabled="errors.any()"/>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import EventBus from "../../event-bus";

    class Errors {
        constructor() {
            this.errors = {};
        }

        any() {
            return Object.keys(this.errors).length > 0;
        }

        has(field) {
            return Object.prototype.hasOwnProperty.call(this.errors, field);
        }

        get(field) {
            if (this.errors[field]) {
                return this.errors[field];
            }
        }

        record(errors) {
            this.errors = errors;
        }

        clear(field) {
            delete this.errors[field];
        }
    }

    export default {
        name: "ModalForward",
        props: ['car'],

        data: function() {
            return {
                name: null,
                email: null,
                sender_email: null,
                message: null,
                placeholder:'I found you a car you might be interested in on Terrific.ie.',
                response: null,

                errors: new Errors(),
            }
        },

        methods: {
            closeModal() {
                EventBus.$emit('closeModal');
            },

            sendEmail() {
                EventBus.$emit('setIsLoading');

                this.axios.post(process.env.VUE_APP_API_URL  + '/api/forward-form', {
                    'name': this.name,
                    'email': this.email,
                    'sender_email': this.sender_email,
                    'message': this.message != null  && this.message != "" ? this.message : this.placeholder,
                    'car_id': this.car.id,
                })
                    .then(response => {
                        EventBus.$emit('closeModal');
                        EventBus.$emit('unsetIsLoading');
                        EventBus.$emit('openStatusMessageModal', {msg:"Your enquiry has been sent. Thank you!"});

                        // TODO extract to form object
                        this.name = null;
                        this.email = null;
                        this.sender_email = null;
                        this.message = null;
                        this.response = response;
                    })
                    .catch(error => {
                        this.errors.record(error.response.data.errors);
                        EventBus.$emit('unsetIsLoading');
                    });
            },
        }
    }
</script>

<style scoped>
</style>
