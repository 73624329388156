<template>
    <div>
        <dealer-right-contact-form :car="car"  v-if="car.adv_settings == null || car.adv_settings != null && car.adv_settings.email_optin == 1"/>
        <div class="actionbutton actionbutton--blue" v-if="showFinance">
            <a @click="openHirePurchaseModal">
                <div class="actionbutton__icon actionbutton__icon--finance"></div>
                <div class="actionbutton__text">Finance Options</div>
            </a>
        </div>

        <div class="dealerdetails__directions">
<!--            <div class="dealerdetails__directions_details {{detailsModifier}}">-->
            <div class="dealerdetails__directions_details">
                <router-link :to="{path: '/dealer/' + getDealerSlug}" v-if="car.dealer.id!=1">
                    <h3>{{ formattedDisplayName }}</h3>
                </router-link>
                <h3 v-else>{{ formattedDisplayName }}</h3>
                <div class="dealerdetails__directions_rating">
                    <div class="stars stars--med" v-if="true==false">
                        <span v-for="(star,idx) in numberStars" v-bind:key="idx"></span>
                        <span class="stars__empty" v-for="(star,idx) in (5-numberStars)" v-bind:key="idx+5000"></span>
                    </div>
                    <small v-if="true==false">{{ formattedAverageRating }}/5</small>
                </div>
                <address>
                    <div v-html="formattedFullAddress"></div>
                </address>
                <span class="name" v-if="car && car.dealer.id==1">{{ formattedDisplayNameBottom }}</span>
                <span class="telephone">{{ formattedPhone }}</span>
                <span class="web"><a target="_blank" rel="noopener" :href="formattedWeb" class="green">{{ formattedWeb }}</a></span>
            </div>

            <div class="dealerdetails__directions_map-wraper" v-if="showMap && car.dealer.id!=1">
                <div id="map" :style="getMapImage()"></div>
            </div>

            <div class="dealerdetails__directions_content" v-if="showMap && car.dealer.id!=1">
                <form action="javascript:void(0)">
                    <legend>Get Directions</legend>
                    <div class="dealerdetails__directions_formgroup">
                        <input name="location" :placeholder=placeholderMap class="form-control" v-model="startLocation">
                        <button v-on:click="goToMap()">Go</button>
                    </div>
                </form>
                <small>{{ formattedLatLon }}</small>
            </div>

        </div>

        <div class="dealerdetails__hours" v-if="car.dealer && car.dealer.id!=1">
            <h2>Opening Hours</h2>
            <dealer-hours :hours="this.car.dealer.hours"/>
        </div>

        <div class="dealerdetails__reviews" v-if="car.dealer && car.dealer.reviews.length > 0 && true==false">
            <h2>Reviews</h2>
            <div class="dealerdetails__reviews_avg">
                <small>{{ formattedAverageRating }}/5<span>avg</span></small>
                <div class="stars stars--large">
                    <span v-for="(star,idx) in numberStars" v-bind:key="idx"></span>
                    <span class="stars__empty" v-for="(star,idx) in (5-numberStars)" v-bind:key="idx+1000"></span>
                </div>
            </div>

            <dealer-small-review v-for="(review,idx) in car.dealer.reviews" :review="review" v-bind:key="idx" />

            <router-link :to="{ name: 'dealer-reviews', params: { dealerSlug:  car.dealer.slug }}" class="green">Read All Reviews</router-link>
        </div>

        <div class="dealerdetails__business" v-if="car.dealer.id != 1">
            <h2>Business Details</h2>
            <dl>
                <dt>{{ formattedPrincipalTitle }}:</dt>
                <dd>{{ formattedPrincipalName }}</dd>
            </dl>
            <dl>
                <dt>Legal Status:</dt>
                <dd>{{ formattedLegalStatus }}</dd>
            </dl>
            <dl>
                <dt>In Business:</dt>
                <dd>{{ formattedInBusiness }}</dd>
            </dl>
            <dl>
                <dt>Memberships:</dt>
                <dd >
                    <div v-for="(item, key) in memberships" :key="key">
                        {{ item }}
                    </div>
                </dd>
            </dl>
            <dl>
                <dt>Number of Staff:</dt>
                <dd>{{ formattedStaffCount }}</dd>
            </dl>
            <dl>
                <dt>Franchises:</dt>
                <dd>{{ formattedFranchises }}</dd>
            </dl>
            <router-link :to="{path: '/dealer/' + getDealerSlug}" class="green">
                More About This Dealership
            </router-link>

            <div class="media" v-if="isVideoAvailable">
                <div class="iframe-container">
                    <div id="player"></div>
                </div>
                <v-lazy-image :src="videoImage" :src-placeholder="placeholderLazyImg" v-if="!showIframe && isVideoAvailable" width="338" height="190" alt="Youtube Video" class="youtube-video"/>
                <div class="player" id="playerSimple"  slot="button-next" @click="playVideo" v-if="!showIframe && isVideoAvailable">
                    <div class="youtube-play"></div>
                </div>
            </div>
        </div>

        <div class="dealerdetails__team" v-if="car.dealer && car.dealer.team_members.length > 0">
            <h2>Meet the Team</h2>

            <div class="dealerdetails__team_people">
                <dealer-team-member v-for="teamMember in car.dealer.team_members" :team-member="teamMember" :key="teamMember.id" class="dealer-member" />
            </div>
        </div>
    </div>
</template>

<script>
    import DealerHours from '@/components/partials/panels/DealerHours'
    import DealerTeamMember from  '@/components/partials/panels/DealerTeamMember'
    import DealerSmallReview from '@/components/partials/panels/DealerSmallReview'
    import DealerRightContactForm from '@/components/partials/panels/DealerRightContactForm'
    import {mapState, mapMutations} from 'vuex'
    import EventBus from "../../event-bus"
    import { isMobile } from 'mobile-device-detect'
    import VLazyImage from "v-lazy-image"

    var numeral = require('numeral')
    var moment = require('moment')

    export default {
        name: "DealerDetailsRight",
        components: {
            DealerRightContactForm,
            DealerSmallReview,
            DealerTeamMember,
            DealerHours,
            VLazyImage
        },
        props: ['car'],
        data: function () {
            return {
                mapsUrl: "https://maps.googleapis.com/maps/api/js?key=" + process.env.VUE_APP_GOOGLE_API_KEY + "&callback=initMap",
                showIframe: false
            }
        },
        mounted() {
            if(this.car.dealer.id==1){
            }
        },
        computed: {
            ...mapState([
                'mapStartLocation'
            ]),
            showFinance(){
              return this.car && this.car.dealer.show_finance_on_terrific == 1;
            },
            isVideoAvailable() {
                return this.car.dealer.youtube_id != null ? true : false;
            },
            videoImage() {
                return this.getVideoImage();
            },
            placeholderLazyImg(){
                return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
            },
            startLocation:{
                set(location){
                    this.setMapStartLocation(location);
                },
                get(){
                    return this.$store.state.mapStartLocation
                }
            },
            // getMapImage(){
            //     let url;
            //     let res;
            //     if (this.car.dealer !=null && this.car.dealer.address.map_lat != null && this.car.dealer.address.map_long != null) {
            //         url =  'https://maps.googleapis.com/maps/api/staticmap?markers=' + this.car.dealer.address.map_lat + '%2c%20' + this.car.dealer.address.map_long + '&zoom=' + parseInt(this.car.dealer.address.map_zoom) + ' &size=378x300&key=AIzaSyDr6Tp82RRNEnr0OnD9rDfeVVlADTe1WFU';
            //         res = "background-image: url('" + url + "');";
            //     }else{
            //         url = 'javascript:void(0)';
            //         res = "background-image: url('" + require(url) + "');";
            //     }
            //
            //     return res;
            //     // return "background-image: url('" + require('../../assets/img/staticmap.png') + "');";
            // },
            placeholderMap(){
                return isMobile ? 'Starting from current location' : 'Starting from';
            },
            dealerSlug(){
                return this.car !=null && this.car.dealer != null ? this.car.dealer.slug : null;
            },
            showMap(){
                return this.$router.currentRoute.name != 'dealer-directions';
            },
            mapCenter() {
                if (this.car.dealer !=null && this.car.dealer.address.map_lat != null && this.car.dealer.address.map_long != null) {
                    return {
                        lat: parseFloat(this.car.dealer.address.map_lat),
                        lng: parseFloat(this.car.dealer.address.map_long)
                    };
                } else {
                    return {lat: 0, lng: 0};
                }
            },

            formattedLatLon() {
                if(this.car.dealer.id == 1) {
                    return "";
                }else {
                    return this.car.dealer != null && this.car.dealer.address.map_lat != null && this.car.dealer.address.map_long != null ? this.car.dealer.address.map_lat + ', ' + this.car.dealer.address.map_long : '';
                }
            },

            zoom() {
                return this.car.dealer !=null && this.car.dealer.address.map_zoom ? parseInt(this.car.dealer.address.map_zoom) : 0;
            },

            formattedDisplayName() {
                let res = '';
                if(this.car.dealer.id==1){
                    res = "Private Seller";
                }else{
                    res =  this.car.dealer != null && this.car.dealer.display_name != null ? this.car.dealer.display_name : '';
                }

                return res;
            },

            formattedDisplayNameBottom()
            {
                let res = '';
                if(this.car && this.car.dealer.id==1 && this.car.adv_settings && this.car.adv_settings.show_name == 1){
                    res = this.car.adv_settings.contact_name;
                }

                return res;
            },
            getDealerSlug(){
                return this.car.dealer != null ? this.car.dealer.slug : '';
            },
            formattedFullAddress(){
                let textHtml = '';

                if(this.formattedAddress1.length > 0){
                    textHtml += this.formattedAddress1;
                }
                if(this.formattedAddress2.length > 0){
                    textHtml += textHtml.length > 0 ? "<br>" + this.formattedAddress2 : this.formattedAddress2;
                }
                if(this.formattedAddress3.length > 0){
                    textHtml += textHtml.length > 0 ? "<br>" + this.formattedAddress3 : this.formattedAddress3;
                }
                if(this.formattedAddress4.length > 0){
                    textHtml += textHtml.length > 0 ? "<br>" + this.formattedAddress4 : this.formattedAddress4;
                }
                if(this.formattedAddress5.length > 0){
                    textHtml += textHtml.length > 0 ? "<br>" + this.formattedAddress5 : this.formattedAddress5;
                }
                if(this.formattedCounty.length > 0){
                    textHtml += textHtml.length > 0 ? "<br>" + this.formattedCounty : this.formattedCounty;
                }
                if(this.formattedPostcode.length > 0){
                    textHtml += textHtml.length > 0 ? "<br>" + this.formattedPostcode : this.formattedPostcode;
                }
                return textHtml;
            },
            formattedAddress1() {
                if(this.car.dealer.id==1){
                         return '';
                }else{
                    return this.car.dealer != null && this.car.dealer.address.address1 != null ? this.car.dealer.address.address1 : '';
                }
            },

            formattedAddress2() {
                if(this.car.dealer.id==1){
                    return "";
                }else{
                    return this.car.dealer != null && this.car.dealer.address.address2 != null ? this.car.dealer.address.address2 : '';
                }
            },

            formattedAddress3() {
                if(this.car.dealer.id == 1){
                    return "";
                }else{
                    return this.car.dealer != null && this.car.dealer.address.address3 != null ? this.car.dealer.address.address3 : '';
                }
            },

            formattedAddress4() {
                if(this.car.dealer.id == 1) {
                    return "";
                }else{
                    return this.car.dealer != null && this.car.dealer.address.address4 != null ? this.car.dealer.address.address4 : '';
                }
            },

            formattedAddress5() {
                if(this.car.dealer.id == 1) {
                    return "";
                }else {
                    return this.car.dealer != null && this.car.dealer.address.address5 != null ? this.car.dealer.address.address5 : '';
                }
            },

            formattedCounty() {
                if(this.car.dealer.id ==  1) {
                    return this.car.location && this.car.location.region ? 'Co. '  + this.car.location.region : '';
                }else {
                    return this.car.dealer != null && this.car.dealer.region != null ? 'Co. ' + this.car.dealer.region : '';
                }
            },

            formattedPostcode() {
                if(this.car.dealer.id == 1) {
                    return "";
                }else {
                    return this.car.dealer != null && this.car.dealer.address.postcode != null ? this.car.dealer.address.postcode : '';
                }
            },

            formattedPhone() {
                if(this.car.dealer.id == 1) {
                    if(this.car.adv_settings && this.car.adv_settings.show_phone==1){
                        return this.car.adv_settings && this.car.adv_settings.phone ? this.car.adv_settings.phone : '';
                    }else{
                        return '';
                    }

                }else {
                    return this.car.dealer != null && this.car.dealer.phone != null ? this.car.dealer.phone : '';
                }
            },

            formattedWeb() {
                if(this.car.dealer.id == 1) {
                    return "";
                }else {
                    return this.car.dealer != null && this.car.dealer.web != null ? this.car.dealer.web : '';
                }
            },

            formattedAverageRating() {
                return this.car.dealer != null && this.car.dealer.average_rating != null ? numeral(this.car.dealer.average_rating).format('0.00') : 0;
            },

            numberStars() {
                return this.car.dealer != null && this.car.dealer.average_rating != null ? Math.round(this.car.dealer.average_rating) : 0;
            },

            formattedPrincipalName() {
                return this.car.dealer != null && this.car.dealer.principal != null ? this.car.dealer.principal : '';
            },

            formattedPrincipalTitle() {
                return this.car.dealer != null && this.car.dealer.principal_title != null ? this.car.dealer.principal_title : 'Dealer Principal';
            },

            formattedLegalStatus() {
                return this.car.dealer != null && this.car.dealer.legal_status != null ? this.car.dealer.legal_status : '';
            },

            formattedInBusiness() {
                return this.car.dealer != null && this.car.dealer.trading_since_date != null ? moment(this.car.dealer.trading_since_date, 'YYYY').fromNow(true) : '';
            },

            memberships() {
                let initialValue = this.car.dealer != null && this.car.dealer.is_simi ? 'SIMI' : '';
                let memberships = [];
                if(initialValue != ''){
                    memberships.push(initialValue);
                }
                if(this.car.dealer.memberships != null ){
                    this.car.dealer.memberships.forEach(function(value) {
                        memberships.push(value.organisation);
                    });
                }
                return memberships;
            },

            formattedStaffCount() {
                    return this.car.dealer != null && this.car.dealer.num_staff != null ? numeral(this.car.dealer.num_staff).format('0') : '';
            },

            formattedFranchises() {
                return this.car.dealer != null && this.car.dealer.franchises.length > 0 ? this.car.dealer.franchises.join(', ') : 'Independent';
            },

            formattedYoutubeUrl() {
                return 'https://www.youtube.com/embed/' + this.car.dealer.youtube_id;
            },
        },

        methods: {
            playVideo(){
                this.showIframe = true;

                // 2. This code loads the IFrame Player API code asynchronously.
                var tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                let myScript = document.createElement('script');
                let data = "" +
                    "                var player;\n" +
                    "                function onYouTubeIframeAPIReady() {\n" +
                    "                    player = new YT.Player('player', {\n" +
                    "                        width: '100%',\n" +
                    "                        videoId: '" + this.car.dealer.youtube_id + "',\n" +
                    "                        playerVars: { 'autoplay': 1, 'playsinline': 1 },\n" +
                    "                        events: {\n" +
                    "                            'onReady': onPlayerReady\n" +
                    "                        }\n" +
                    "                    });\n" +
                    "                }\n" +
                    "\n" +
                    "                // 4. The API will call this function when the video player is ready.\n" +
                    "                function onPlayerReady(event) {\n" +

                    "                    event.target.playVideo();\n" +
                    "                }" +

                    "";

                myScript.appendChild(document.createTextNode(data));
                document.body.appendChild(myScript);

            },
            getVideoImage(){
                let url;
                if (this.isVideoAvailable) {
                    url = process.env.VUE_APP_API_URL + "/api/image-fetch/" + this.car.dealer.youtube_id;
                }
                return url;
            },
            getMapImage(){
                let url = '';
                let res;
                if(this.car.dealer.id == 1) {
                    res = "";
                }else {
                    if (this.car.dealer != null && this.car.dealer.address.map_lat != null && this.car.dealer.address.map_long != null) {
                        url = 'https://maps.googleapis.com/maps/api/staticmap?markers=' + this.car.dealer.address.map_lat + '%2c%20' + this.car.dealer.address.map_long + '&zoom=' + parseInt(this.car.dealer.address.map_zoom) + ' &size=378x300&key=AIzaSyDr6Tp82RRNEnr0OnD9rDfeVVlADTe1WFU';
                        res = "background-image: url('" + url + "'); background-repeat: no-repeat;";
                    }
                }
                return res;
            },
            openHirePurchaseModal() {
                EventBus.$emit('openHirePurchaseModal');
            },
            injectGoogleMaps(){
                // Create the script tag, set the appropriate attributes
                let script = document.createElement('script');
                script.src = this.mapsUrl;
                script.defer = true;
                script.async = true;
                // Append the 'script' element to 'head'
                document.head.appendChild(script);
            },
            callGmaps(){

                // Attach your callback function to the `window` object
                let self = this;
                // let infoWindow;
                window.initMap = function() {
                    // JS API is loaded and available
                    window.map = new window.google.maps.Map(document.getElementById("map"), {
                        center: self.mapCenter,
                        zoom: self.zoom
                    });

                    new window.google.maps.Marker({
                        position: self.mapCenter,
                        map: window.map,
                        title: self.formattedDisplayName
                    });
                };

                if(typeof window.google !== 'undefined'){

                    window.map = new window.google.maps.Map(document.getElementById("map"), {
                        center: self.mapCenter,
                        zoom: self.zoom
                    });

                    new window.google.maps.Marker({
                        position: self.mapCenter,
                        map: window.map,
                        title: self.formattedDisplayName
                    });
                }
            },
            checkGoogleMapsTag(){
                var scripts = document.getElementsByTagName('script');
                for (var i = scripts.length; i--;) {
                    if (scripts[i].src == this.mapsUrl) return true;
                }
                return false;
            },
            goToMap(){
                this.$router.push('/dealer/' + this.dealerSlug + '/directions');
            },
            ...mapMutations([
                'setMapStartLocation',
            ]),
        },
    }
</script>

<style scoped>
</style>
