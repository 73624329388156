<template>
    <div style="min-height: 100vh; height: 100vh">
        <div v-if="isError">
            <seo-meta></seo-meta>
            <div class="quicksearch-overlay" v-on:click="closeOverlay()"  v-if="this.$store.state.quickSearchOverlay"></div>
            <main-header></main-header>
            <http404 />

            <!-- Show suggested ads -->
            <div class="wrapper wrapper--full wrapper--grey">
                <div class="wrapper">
                    <div class="grid grid--usedcars">
                        <div class="usedcars_flex_item usedcars_flex_item--right">
                            <h2 class="pt-1" v-if="showListing">We found similar ads for {{make404}} {{model404}} that you might be interested in.</h2>
                            <car-listings v-show="showListing" :slug="mySlug"></car-listings>
                            <router-link v-if="showListing" :to="{path: mySlug}">Check out more ads for {{make404}} {{model404}}</router-link>
                            <quick-search v-if="!showListing" :monthlyPrice="false"></quick-search>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div v-else>
        <seo-meta></seo-meta>
        <main-header></main-header>
        <breadcrumbs :slug="adSlug"></breadcrumbs>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage">
            </loading>
        </div>

        <div class="ad">

            <ad-galleries :car="this.car" v-if="this.car && this.car.images"></ad-galleries>

            <div class="wrapper"  v-if="car && car.status != false">

                <div class="ad__title">
                    <div class="ad__title_left" v-if="this.car">
                        <h1>{{ this.car.reg_year }}{{ formattedRegPrefix}} {{ this.car.make }} {{ this.car.model }}</h1>
                        <h2>{{ this.car.variant }} {{ this.car.transmission_type }}</h2>
                    </div>
                    <div class="ad__title_right ad__title_right--mobile">

                        <span class="payments" v-if="!checkDisabled( formattedMonthlyPrice )" v-on:click="openHirePurchaseModal">
                            <a class="link-payments" href="javascript:void(0)">
                                <dl>
                                    <dt>from</dt>
                                    <dd>{{ formattedMonthlyPrice }} pm</dd>
                                </dl>
                            </a>
                        </span>

                        <span class="price">{{ formattedCarPrice }}</span>
                    </div>
                    <div class="ad__title_right ad__title_right--desktop">
                        <span class="payments" v-if="!checkDisabled( formattedMonthlyPrice )" v-on:click="openHirePurchaseModal">
                            <a class="link-payments" href="javascript:void(0)">
                                <dl>
                                    <dt>Monthly</dt>
                                    <dd>{{ formattedMonthlyPrice }}</dd>
                                </dl>
                            </a>
                        </span>

                        <span class="price">{{ formattedCarPrice }}</span>
                    </div>
                </div>

                <div class="ad__cols">

                    <div class="ad__left">

                        <div class="ad__body">

                            <div class="ad__dealerinfo">
                                <h3>{{ dealerDisplayName }}</h3>
                                <div class="ad__dealerinfo_left">
                                    <div class="stars stars--med" v-if="false">
                                        <span v-for="(star,idx) in numberStars" v-bind:key="idx"></span>
                                        <span class="stars__empty" v-for="(star,idx) in (5-numberStars)"
                                              v-bind:key="idx+100"></span>
                                    </div>
                                    <small v-if="true==false">{{ formattedAverageRating }}/5</small>
                                </div>
                                <div class="ad__dealerinfo_mid">
                                    <dl v-if="getDistance() > 0">
                                        <dt>{{ getDistance() }} km</dt>
                                        <dd>{{ formattedRegion }}</dd>
                                    </dl>
                                </div>
                                <div class="ad__dealerinfo_right">
                                    <div class="likebutton" :class="liked ? 'likebutton--med--liked' : 'likebutton--med'" @click="likeAdd(car.id)"></div>
                                </div>
                            </div>

                            <div class="ad__cardetails">
                                <h3>Car Details</h3>

                                <ul>
                                    <li v-if="this.car"><span>Make:</span> <span>{{ this.car.make }}</span></li>
                                    <li v-if="this.car"><span>Model:</span> <span>{{ this.car.model }}</span></li>
                                    <li v-if="this.car"><span>Year:</span> <span>{{ this.car.reg_year }}</span></li>
                                    <li v-if="formattedCarPrice"><span>Price:</span> <span>{{ formattedCarPrice }}</span></li>
                                    <li v-if="formattedMonthlyPrice"> <span>Monthly Price From:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedMonthlyPrice ) }"><a href="javascript:void(0)" v-on:click="openHirePurchaseModal">{{ formattedMonthlyPrice }}</a></span>
                                    </li>
                                    <li v-if="formattedEngineSize"><span>Engine Size:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedEngineSize ) }">{{ formattedEngineSize }}</span>
                                    </li>
                                    <li v-if="formattedMileage"><span>Mileage:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedMileage ), disabled: checkDisabled( formattedMileageKm ) }">{{ formattedMileage }} / {{ formattedMileageKm }}</span>
                                    </li>
                                    <li v-if="formattedTransmissionType"><span>Gearbox:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedTransmissionType ) }">{{ formattedTransmissionType }}</span>
                                    </li>
                                    <li v-if="formattedFuelType"><span>Fuel Type:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedFuelType ) }">{{ formattedFuelType }}</span>
                                    </li>
                                    <li v-if="formattedOwners"><span>Owners:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedOwners ) }">{{ formattedOwners }}</span>
                                    </li>
                                    <li v-if="formattedBodyType"><span>Body Type:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedBodyType ) }">{{ formattedBodyType }}</span>
                                    </li>
                                    <li v-if="formattedSeats"><span>Seats:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedSeats ) }">{{ formattedSeats }}</span>
                                    </li>
                                    <li v-if="formattedDoors"><span>Doors:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedDoors ) }">{{ formattedDoors }}</span>
                                    </li>
                                    <li v-if="formattedColour"><span>Colour:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedColour ) }">{{ formattedColour }}</span>
                                    </li>
                                    <!-- <li v-if="formattedEnginePower"><span>Engine Power:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedEnginePower ) }">{{ formattedEnginePower }}</span>
                                    </li> -->
                                    <li v-if="formattedWarranty != null">
                                        <span>Warranty:</span> <span>{{ formattedWarranty }}</span>
                                    </li>
                                    <!-- <li v-if="formattedTorque"><span>Engine Torque:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedTorque ) }">{{ formattedTorque }}</span>
                                    </li> -->
                                    <!-- <li v-if="formattedFuelEconUrban"><span>Fuel Econ Urban:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedFuelEconUrban ) }">{{ formattedFuelEconUrban }}</span>
                                    </li>
                                    <li v-if="formattedFuelEconRural"><span>Fuel Econ Rural:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedFuelEconRural ) }">{{ formattedFuelEconRural }}</span>
                                    </li> -->
                                    <!-- <li v-if="formattedFuelEconCombined"><span>Fuel Econ Combined:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedFuelEconCombined ) }">{{ formattedFuelEconCombined }}</span>
                                    </li> -->
                                    <li v-if="formattedRoadTax"><span>Road Tax:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedRoadTax ) }">{{ formattedRoadTax }}</span>
                                    </li>
                                    <li v-if="formattedRoadTaxDue"><span>Road Tax Due:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedRoadTaxDue ) }">{{ formattedRoadTaxDue }}</span>
                                    </li>
                                    <li v-if="formattedNCTDue"><span>NCT Due:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedNCTDue ) }">{{ formattedNCTDue }}</span>
                                    </li>
                                    <!-- <li v-if="formattedAcceleration"><span>0 - 62 mph:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedAcceleration ) }">{{ formattedAcceleration }}</span>
                                    </li> -->
                                    <!-- <li v-if="formattedTopSpeed"><span>Top Speed:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedTopSpeed ) }">{{ formattedTopSpeed }}</span>
                                    </li> -->
                                    <!-- <li v-if="ncapChild || ncapAdult || environmental || ncapOverall"><span class="ad__cardetails_title">Ratings</span></li> -->
                                    <!-- <li v-if="ncapChild">
                                        <span>Child Safety:</span>
                                        <span v-bind:class="{ disabled: checkDisabled( ncapChild ) }">
                                            <span v-if="checkDisabled( ncapChild )">{{ncapChild}}</span>
                                            <div class="stars stars--med" v-if="!checkDisabled( ncapChild )">
                                                <span v-for="(star,idx) in ncapChild" v-bind:key="idx+110"></span>
                                                <span class="stars__empty" v-for="(star, idx) in 5-ncapChild" v-bind:key="idx+120"></span>
                                            </div>
                                        </span>
                                    </li> -->
                                    <!-- <li v-if="ncapAdult">
                                        <span>Adult Safety:</span>
                                        <span v-bind:class="{ disabled: checkDisabled( ncapAdult ) }">
                                            <span v-if="checkDisabled( ncapAdult )">{{ncapAdult}}</span>
                                            <div class="stars stars--med" v-if="!checkDisabled( ncapAdult )">
                                                <span v-for="(star, idx) in ncapAdult" v-bind:key="idx+130"></span>
                                                <span class="stars__empty" v-for="(star, idx) in 5-ncapAdult" v-bind:key="idx+140"></span>
                                            </div>
                                        </span>
                                    </li> -->
                                    <!-- <li v-if="ncapOverall">
                                        <span>Overall Safety:</span>
                                        <span v-bind:class="{ disabled: checkDisabled( ncapOverall ) }">
                                            <span v-if="checkDisabled( ncapOverall )">{{ncapOverall}}</span>
                                            <div class="stars stars--med" v-if="!checkDisabled( ncapOverall )">
                                                <span v-for="(star,idx) in ncapOverall" v-bind:key="idx+150"></span>
                                                <span class="stars__empty" v-for="(star, idx) in 5-ncapOverall" v-bind:key="idx+160"></span>
                                            </div>
                                        </span>
                                    </li> -->
                                    <!-- <li v-if="environmental">
                                        <span>Environmental:</span>
                                        <span v-bind:class="{ disabled: checkDisabled( environmental ) }">
                                            <span v-if="checkDisabled( environmental )">{{ environmental }}</span>
                                            <div class="stars stars--med stars--green" v-if="!checkDisabled( environmental )">
                                                <span v-for="(star, idx) in environmental" v-bind:key="idx+170"></span>
                                                <span class="stars__empty" v-for="(star, idx) in 5-environmental" v-bind:key="idx+180"></span>
                                            </div>
                                        </span>
                                    </li> -->
                                    <li v-if="formattedUpdatedAtDate"><span>Last Updated:</span> <span v-bind:class="{ disabled: checkDisabled( formattedUpdatedAtDate ) }">{{ formattedUpdatedAtDate }}</span></li>
                                </ul>
                            </div>

                            <div class="ad__info">
                                <h3>Vehicle Features:</h3>
                                <p v-if="this.car && this.car.description" class="dont-break-out">{{ car.description.replace(/\.$/, '').replace(/\*\*.\d+$/, '').trim() + '.' }} <span v-if="this.getDescriptionData().length > 0">{{this.getDescriptionData()}}</span> &nbsp; {{ this.getScrapeDetectionInfo() }}</p>
                                <!-- <h3>More Information</h3>

                                <ad-collapsable-info :car="car"></ad-collapsable-info> -->

                            </div>
                        </div>

                        <ad-videos :car="car" v-if="this.car"></ad-videos>

                        <dealer-bottom-contact-form :car="car" v-if="car && car.adv_settings == null || car && car.adv_settings != null && car.adv_settings.email_optin == 1"/>

                        <ad-action-links v-if="this.car && typeof this.car.status == 'undefined'" :ad-id="this.car.id" :make="this.car.make" :model="this.car.model" :slug="this.car.slug"></ad-action-links>
                    </div>

                    <div class="ad__right">

                        <dealer-details-right v-if="this.car && typeof this.car.status == 'undefined'" :car="this.car" :key="this.car.id"></dealer-details-right>

                    </div>

                </div>

                <div class="ad__disclaimer">
                    <strong>IMPORTANT</strong> The data displayed above includes the usual specification of the most recent model of this
                    vehicle. It is not necessarily the exact data for the actual vehicle being offered for sale and data
                    for older models may vary. Furthermore please be aware that a vehicle's performance may deteriorate
                    over time. We recommend that you always check the details with the seller prior to purchase. We do
                    not give any warranty or representation, express or implied, about the accuracy, completeness,
                    merchantability or appropriateness of any information, materials or graphics on this website.
                </div>

            </div>

        </div>

        <image-gallery v-if="imageGalleryOpen" :car="car"></image-gallery>

        <video-modal v-if="modalVideoOpen" :car="car"></video-modal>

        <modal-forward v-if="modalForwardOpen" :car="car"></modal-forward>

        <modal-status-message v-if="modalStatusMessageOpen" :msg="statusMessage"></modal-status-message>

        <floating-buttons-ad-detail :car="this.car" v-if="this.car && typeof this.car.status == 'undefined'"></floating-buttons-ad-detail>

<!--        <div v-if="loadThumbs && this.car && this.car.images">-->
<!--            <div style="display: none"  v-for="(image, index) in getGalleryImages" :id="index" :key="index">-->
<!--                <img :src="image.primaryURL.xs" width="119" height="90" />-->
<!--            </div>-->
<!--        </div>-->

        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import DealerDetailsRight from '@/components/partials/DealerDetailsRight'
    import AdGalleries from '@/components/partials/AdGalleries'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'
    import AdCollapsableInfo from '@/components/partials/AdCollapsableInfo'
    import AdVideos from '@/components/partials/AdVideos'
    import AdActionLinks from '@/components/partials/AdActionLinks'
    import DealerBottomContactForm from '@/components/partials/panels/DealerBottomContactForm'
    import ImageGallery from '@/components/partials/ImageGallery'
    import ModalForward from '@/components/partials/ModalForward'
    import ModalStatusMessage from "../components/partials/ModalStatusMessage"
    import FloatingButtonsAdDetail from '@/components/partials/FloatingButtonsAdDetail'
    import EventBus from "../event-bus"
    import VideoModal from "../components/partials/VideoModal"
    import SeoMeta from "../components/partials/SeoMeta"
    import Loading from 'vue-loading-overlay'
    import { isMobile } from 'mobile-device-detect'
    import $ from "jquery";
    import http404 from '@/components/http404.vue'
    import CarListings from '@/components/partials/CarListings'
    import QuickSearch from "../components/QuickSearch";


    var moment = require('moment')
    var numeral = require('numeral')
    export default {
        name: "AdDetail",
        components: {
            QuickSearch,
            http404,
            CarListings,
            Loading,
            ModalStatusMessage,
            SeoMeta,
            VideoModal,
            FloatingButtonsAdDetail,
            ModalForward,
            ImageGallery,
            DealerBottomContactForm,
            // DealerRightContactForm,
            AdActionLinks,
            AdVideos,
            AdCollapsableInfo,
            Breadcrumbs,
            AdGalleries,
            // ExpertReview,
            DealerDetailsRight,
            MainHeader,
            MainFooter
        },
        props: {
            slug: {
                type: String,
                required: true
            }
        },
        watch: {
            '$route.params': function () {
                let res = '/used-cars/makes-' + this.$router.currentRoute.path.split('/r/')[0].split('/makes-')[1].match(/^(.+?)\//)[0].slice(0, -1) + '/models-' + this.$router.currentRoute.path.split('/r/')[0].split('/models-')[1];
                let mySlugTemp = res + this.$router.currentRoute.params.slug;
                this.$store.dispatch('ads/fetchAd', {slug: this.$router.currentRoute.params.slug.split('/r/')[1]});
                if(this.car==false || typeof this.$store.state.ads.ad[this.$router.currentRoute.params.slug.split('/r/')[1]] == 'undefined'){
                    this.fetchSearchResults(mySlugTemp);
                    this.$store.dispatch('ads/fetchMakeModelBySlug', {make: this.routeMake, model: this.routeModel});
                }
            }
        },
        beforeRouteLeave (to, from, next) {
            if (typeof window !== 'undefined') {
                //Removes the overflow hidden that the gallery might set
                $('body').css('overflow', 'unset');
                EventBus.$emit('closeImageGallery');
            }
            next();
        },

        data: function () {
            const currentPath = this.$router.currentRoute.path;
            let routeMake = '';
            let routeModel = '';
            let mySlug = '';

            // Check if the URL contains '/r/' segment
            if (currentPath.includes('/r/')) {
                const tmpMake = currentPath.split('/r/')[0].split('/makes-');
                routeMake = (tmpMake > 1) ? tmpMake[1].match(/^(.+?)\//)[0].slice(0, -1) : null;
                const tmpModel = currentPath.split('/r/')[0].split('/models-');
                routeModel = (tmpModel > 1) ? tmpModel[1] : null;
                mySlug = '/used-cars/makes-' + routeMake + '/models-' + routeModel;
            } else {
                // Handle the case without '/r/' segment
                // For example, set default values or handle differently
                routeMake = 'defaultMake';
                routeModel = 'defaultModel';
                mySlug = '/used-cars/makes-' + routeMake + '/models-' + routeModel;
            }
            return {
                routeMake: routeMake,
                routeModel: routeModel,
                mySlug: mySlug,
                adSlug: this.slug.split("/").pop(),
                isLoading: false,
                loadThumbs: false,
                fullPage: true,
                section1visible: false,
                section2visible: false,
                section3visible: false,
                section4visible: false,
                section5visible: false,
                section6visible: false,
                distance: 0,
                statusMessage: null,
                modalForwardOpen: false,
                modalStatusMessageOpen: false,
                imageGalleryOpen: false,
                modalVideoOpen: false,

                likes: [],
                liked: false,
            }
        },

        computed: {
            car() {
                if(['vehicle-detail-adv', 'vehicle-detail-adv'].indexOf(this.$router.currentRoute.name) != -1){
                    return this.$store.state.ads.ad[this.slug];
                }else{
                    if(typeof this.$store.state.ads.ad[this.getRealSlug] == 'undefined'){
                        return false;
                    }else{
                        return this.$store.state.ads.ad[this.getRealSlug];
                    }
                }
            },
            showListing() {
                return (this.isError && this.searchResultData?.hits?.hits?.length > 0) ?? false;
            },
            getRealSlug(){
                let queryString = '';

                if(this.$route.query.deposit !== 'undefined' && this.$route.query.deposit > 0){
                    queryString = queryString + '&deposit=' + this.$route.query.deposit;
                }
                if(this.$route.query.priceFrom !== 'undefined' && this.$route.query.priceFrom > 0){
                    queryString = queryString + '&priceFrom=' + this.$route.query.priceFrom;
                }
                if(this.$route.query.priceTo !== 'undefined' && this.$route.query.priceTo > 0){
                    queryString = queryString + '&priceTo=' + this.$route.query.priceTo;
                }

                if(queryString.length > 0 && queryString.charAt(0) == '&'){
                    queryString = '?' + queryString.substring(1);
                }
                return this.$router.currentRoute.params.slug.split('/r/')[1] + queryString;
            },
            searchResultData () {
                return this.$store.state.searchResults[this.mySlug];
            },
            make404(){
                return this.$store.state.ads.make404;
            },
            model404(){
                return this.$store.state.ads.model404;
            },
            isError(){
                return this.$store.state.ads.error404;
            },
            mainImage() {
                return this.car != null && this.car.images.length > 0 && this.car.images[0].primaryURL.xl != null ? this.car.images[0].primaryURL.xl : require('../assets/img/photos/missing/large.jpg');
            },
            dealerDisplayName(){
                return this.car != null && this.car.dealer ? this.car.dealer.display_name : null;
            },
            formattedTransmissionType() {
                return this.car != null && this.car.transmission_type != null ? this.car.transmission_type : null;
            },
            formattedFuelType() {
                return this.car != null && this.car.fuel_type != null ? this.car.fuel_type : null;
            },
            formattedOwners() {
                return this.car != null && this.car.owners != null ? this.car.owners : null;
            },
            formattedBodyType() {
                return this.car != null && this.car.body_type != null ? this.car.body_type : null;
            },
            formattedSeats() {
                return this.car != null && this.car.seats != null ? this.car.seats : null;
            },
            formattedDoors() {
                return this.car != null && this.car.doors != null ? this.car.doors : null;
            },
            formattedColour() {
                return this.car != null && this.car.colour != null ? this.car.colour : null;
            },
            formattedRegion() {
                return this.car != null && this.car.dealer != null && this.car.dealer.region != null ? this.car.dealer.region : '';
            },

            formattedUpdatedAtDate() {
                return this.car && this.car.dealer != null && this.car.dealer.stock_updated_at != null ? moment(this.car.dealer.stock_updated_at).format('DD/MM/YYYY') : null;
            },

            formattedEngineSize() {
                return this.car && this.car.engine_cc != null ? numeral(this.car.engine_cc / 1000).format('0.0') : null;
            },

            formattedEnginePower() {
                let torque = this.car ?  this.car.max_power_bhp : null;
                return torque != null ?
                    numeral(torque).format('0') + ' BHP' : null;
            },

            formattedWarranty(){
                let months = this.car != null && this.car.warranty_period_months != null ? this.car.warranty_period_months : null;
                let res = null;

                if(months != null){
                    if(months < 12){
                        res = months + ' Months';
                    }else{
                        let years = parseInt(months / 12);
                        let suffix =  years > 1 ? ' Years' : ' Year';
                        res = years.toString() + suffix;
                   }
                }
                return res;
            },

            formattedTorque() {
                let res = null;
                if(this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.max_torque_nm != null){
                    if(this.car.fuel_type && this.car.fuel_type.toLowerCase() == 'electric'){
                        res = numeral(this.car.ad_extra_info.max_torque_nm).format('0') + ' Nm';
                    }else{
                        res = numeral(this.car.ad_extra_info.max_torque_nm).format('0') + ' Nm @ ' + numeral(this.car.ad_extra_info.max_torque_revs).format('0') + ' rpm';
                    }

                }

                return res;
            },

            formattedFuelEconUrban() {
                let mpg = this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_urban != null ? numeral(this.car.ad_extra_info.fuel_cons_urban).format('0.0') + ' mpg' : '';
                if (mpg === '') {
                    return null;
                }
                return mpg;
            },

            formattedFuelEconRural() {
                let mpg = this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_extra_urban != null ? numeral(this.car.ad_extra_info.fuel_cons_extra_urban).format('0.0') + ' mpg' : '';
                if (mpg === '') {
                    return null;
                }
                return mpg;
            },

            formattedFuelEconCombined() {
                let mpg = this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_combined != null ? numeral(this.car.ad_extra_info.fuel_cons_combined).format('0.0') + ' mpg' : '';
                if (mpg === '') {
                    return null;
                }
                return mpg;
            },

            formattedRoadTax() {
                return this.car && this.car.road_tax_cost != null ? '€' + numeral(this.car.road_tax_cost).format('0') : null;
            },

            formattedRoadTaxDue() {
                return this.car && this.car.tax_due_date != null ? moment(this.car.tax_due_date).format('DD/MM/YYYY') : null;
            },

            formattedAcceleration() {
                return this.car && this.car.acceleration != null ? numeral(this.car.acceleration).format('0.0') + ' seconds' : null;
            },

            formattedTopSpeed() {
                return this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.max_speed_mph != null ? numeral(this.car.ad_extra_info.max_speed_mph).format('0') + ' mph' : null;
            },

            formattedMileage() {
                return this.car && this.car.mileage != null ? numeral(this.car.mileage).format('0,0') + " mi" : null;
            },

            formattedMileageKm() {
                return this.car && this.car.kilometres != null ? numeral(this.car.kilometres).format('0,0') + " km" : null;
            },

            formattedNCTDue() {
                return this.car && this.car.test_due_date != null ? moment(this.car.test_due_date).format('DD/MM/YYYY') : null;
            },

            formattedRegPrefix() {
                return this.car && this.car.reg_prefix != null ? ' (' + this.car.reg_prefix + ')' : '';
            },

            formattedCarPrice() {
                return this.car && this.car.price != null && this.car.price > 10 ? '€' + numeral(this.car.price).format('0,0') : 'POA';
            },

            formattedMonthlyPrice() {
                return this.car && this.car.from_monthly != null ? '€' + numeral(this.car.from_monthly).format('0,0') : null;
            },

            ncapChild() {
                return this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_child_pct != null ? Math.ceil(((parseInt(this.car.ad_extra_info.ncap_child_pct) * 5) / 100)) : null;
            },

            ncapAdult() {
                return this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_adult_pct != null ? Math.ceil(((parseInt(this.car.ad_extra_info.ncap_adult_pct) * 5) / 100)) : null;
            },

            ncapOverall() {
                return this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_overall != null ? Math.ceil(this.car.ad_extra_info.ncap_overall) : null;
            },

            environmental() {
                return this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.co2_stars != null ? Math.ceil(this.car.ad_extra_info.co2_stars) : null;
            },

            numberStars() {
                return this.car != null && this.car.dealer != null && this.car.dealer != null && this.car.dealer.average_rating != null ? Math.round(this.car.dealer.average_rating) : 0;
            },
            formattedAverageRating() {
                return this.car != null && this.car.dealer != null && this.car.dealer != null && this.car.dealer.average_rating != null ? numeral(this.car.dealer.average_rating).format('0.00') : 0;
            },
            getGalleryImages() {
                if(this.car == null){
                    return false;
                }
                let arr = [];
                let maxSlice = this.car.images.length >= 2 ? 2 : this.car.images.length;
                arr.push(...this.car.images.slice(0, maxSlice));

                if (this.isVideoAvailable) {
                    let video = {
                        'primaryURL': {
                            'xl': this.getVideoImage(),
                            'xs': this.getVideoImage()
                        },
                        'hasVideo' : true
                    };
                    arr.push(video);
                }

                if (this.car.images.length > 2) {
                    arr.push(...this.car.images.slice(2));
                }

                return arr;
            },
            isVideoAvailable() {
                return this.car.youtube_id != null ? true : false;
            },
        },

        serverPrefetch() {
            this.$ssrContext.title = '';
            this.$ssrContext.description = '';
            this.$ssrContext.keywords = '';
            this.$ssrContext.robots = '';
            this.$ssrContext.canonical = process.env.VUE_APP_BASE_URL + this.$router.currentRoute.path;
            this.fetchSearchResults(this.mySlug);
            return this.getAd();
        },

        mounted() {
            if (this.isError && this.searchResultData == null) {
                this.fetchSearchResults(this.mySlug);
            }


            if (!this.car) {
                this.getAd();
            }

            if (this.car && typeof this.car.status !== 'undefined' && this.car.status==false) {
                return false;
            }

            this.loadThumbnails();

            this.loadLikes();
            this.checkLickedAd();

            let that = this;

            EventBus.$on('closeModal', function () {
                that.modalForwardOpen = false;
                that.modalStatusMessageOpen = false;
            })

            EventBus.$on('openForwardModal', function () {
                that.modalForwardOpen = true;
            })

            EventBus.$on('openStatusMessageModal', function (params) {
                that.statusMessage = params.msg;
                that.modalStatusMessageOpen = true;
            })

            EventBus.$on('closeImageGallery', function () {
                that.imageGalleryOpen = false;
            })

            EventBus.$on('openImageGallery', function () {
                that.imageGalleryOpen = true;
            })

            EventBus.$on('closeVideoModal', function () {
                that.modalVideoOpen = false;
            })

            EventBus.$on('openVideoModal', function () {
                that.modalVideoOpen = true;
            })

            EventBus.$on('setIsLoading', function(){
                that.isLoading = true;
            })

            EventBus.$on('unsetIsLoading', function(){
                that.isLoading = false;
            })


            window.scrollTo(0, 0);


        },

        methods: {
            async fetchSearchResults (url) {
                // return the Promise from the action
                // if( typeof document !== 'undefined' && this.getCookie("g") == 1 ) {
                //     this.$store.commit('setDealerGroup', 1);
                // }
                let res = await this.$store.dispatch('fetchSearchResults', url);
                this.updateBreadCrumb();
                this.fetchIndex = 0;
                return res;
            },
            closeOverlay(){
                this.$store.state.quickSearchOverlay = false;
            },
            capitalized(str){
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
            getDescriptionData(){
                let res = [];
                if(typeof this.car.features !== 'undefined'){
                    res = this.car.features.map((val) => val.description);
                }
                return res.join(', ');
            },
            getScrapeDetectionInfo(){
                let res = '';
                if(this.car.dealer.scrape_detection == 1){
                    if(typeof window == "undefined"){
                        res = '##' + this.car.id;
                    }else{
                        res = '\r\n#' + this.car.id;
                    }
                }
                return res;
            },
            getVideoImage(){
                let url;
                if (this.isVideoAvailable) {
                    url = "https://img.youtube.com/vi/" + this.car.youtube_id + "/0.jpg";
                }
                return url;
            },
            stringToSlug(str) {
                if( typeof str == 'undefined'){
                    return false;
                }
                str = str.replace(/^\s+|\s+$/g, ''); // trim
                str = str.toLowerCase();

                // remove accents, swap ñ for n, etc
                let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
                let to = "aaaaeeeeiiiioooouuuunc------";
                for (let i = 0, l = from.length; i < l; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                    .replace(/\s+/g, '+') // collapse whitespace and replace by -
                    .replace(/-+/g, '+'); // collapse dashes

                return str;
            },
            async getAd() {
                let queryString = '';

                if(this.$route.query.deposit !== 'undefined' && this.$route.query.deposit > 0){
                    queryString = queryString + '&deposit=' + this.$route.query.deposit;
                }
                if(this.$route.query.priceFrom !== 'undefined' && this.$route.query.priceFrom > 0){
                    queryString = queryString + '&priceFrom=' + this.$route.query.priceFrom;
                }
                if(this.$route.query.priceTo !== 'undefined' && this.$route.query.priceTo > 0){
                    queryString = queryString + '&priceTo=' + this.$route.query.priceTo;
                }

                if(queryString.length > 0 && queryString.charAt(0) == '&'){
                    queryString = '?' + queryString.substring(1);
                }

                this.adSlug = this.adSlug + queryString;

                if(typeof window !== 'undefined'){
                    this.isLoading = true;
                }

                await this.$store.dispatch('ads/fetchAd', {slug: this.adSlug});
                this.updateBreadCrumb();

                if(typeof window !== 'undefined'){
                    this.isLoading = false;
                }

                if (!this.car || (typeof this.car.status !== 'undefined' && this.car.status==false)) {
                    return false;
                }else if(this.car.toString().startsWith('Error')){
                    await this.$store.dispatch('ads/fetchMakeModelBySlug', {make: this.routeMake, model: this.routeModel});
                }



            },
            openHirePurchaseModal() {
                EventBus.$emit('openHirePurchaseModal');
            },
            openForwardModal() {
                this.modalForwardOpen = true;
            },
            openStatusMessageModal() {
                this.openStatusMessageModal = true;
            },
            checkDisabled(val) {
                return val == null;
            },

            loadLikes() {
                this.likes = localStorage.getItem('likes') != null ? JSON.parse(localStorage.getItem('likes')) : [];
            },
            loadThumbnails(){
                this.loadThumbs = true;
            },
            likeAdd(adId) {
                this.loadLikes();

                if (!this.likes.includes(adId)) {
                    this.likes.push(adId);
                } else {
                    this.likes = this.likes.filter(element => element != adId);
                }
                localStorage.setItem('likes', JSON.stringify(this.likes));
                this.checkLickedAd();
            },
            checkLickedAd() {
                if(this.car != null) {
                    if (this.likes.includes(this.car.id)) {
                        this.liked = true;
                    } else {
                        this.liked = false;
                    }
                }
            },
            updateBreadCrumb(){
                if(this.car == null) {
                    return false;
                }
                let make = this.car ? this.car.make : '';
                let model = this.car ? this.car.model : '';
                let year =  this.car ? this.car.reg_year : '';

                this.$store.commit('setInternalSource',  false);

                this.$store.commit('breadcrumbs/setLevelOne',  true);
                this.$store.commit('breadcrumbs/setFirstLevelName',  "Used Cars");
                this.$store.commit('breadcrumbs/setLevelTwo',  true);

                this.$store.commit('breadcrumbs/setSecondLevelName',  make);
                this.$store.commit('breadcrumbs/setLevelThree',  true);
                this.$store.commit('breadcrumbs/setThirdLevelName',  model);

                this.$store.commit('breadcrumbs/setLevelFour',  true);
                this.$store.commit('breadcrumbs/setFourthLevelName',  year);
                this.$store.commit('breadcrumbs/setLeafLevelName',  'Details');

                this.$store.commit('breadcrumbs/setPathLevelOne',  '/used-cars');
                this.$store.commit('breadcrumbs/setPathLevelTwo',  '/used-cars/makes-' + this.stringToSlug(make));
                this.$store.commit('breadcrumbs/setPathLevelThree',  '/used-cars/makes-' + this.stringToSlug(make) + '/models-' + this.stringToSlug(model));
                this.$store.commit('breadcrumbs/setPathLevelFour',  '/used-cars/makes-' + this.stringToSlug(make) + '/models-' + this.stringToSlug(model) + '/yearfrom-' + year + '/yearto-' + year);

            },
            getDistance(){

                if(this.car && this.car.dealer && this.car.dealer.id == 1) {
                    return false;
                }

                if(typeof window === 'undefined' || this.car == null ||  (this.car.dealer && this.car.dealer.address && this.car.dealer.address.map_long == null) || (this.car.dealer && this.car.dealer.address && this.car.dealer.address.map_long == "") || isMobile === false ){
                    return 0;
                }
                let that = this;
                window.navigator.geolocation.getCurrentPosition(function(pos) {
                    var lat = pos.coords.latitude;
                    var lon = pos.coords.longitude;
                    that.distance = that.calculateDistance(lon, lat, that.car.dealer.address.map_long, that.car.dealer.address.map_lat);
                 })
                return this.distance > 10 ? parseFloat(this.distance).toFixed(0) : parseFloat(this.distance).toFixed(1);
            },
            calculateDistance(lon1, lat1, lon2, lat2) {
                /** Converts numeric degrees to radians */
                if (typeof(Number.prototype.toRad) === "undefined") {
                    Number.prototype.toRad = function() {
                        return this * Math.PI / 180;
                    }
                }
                var R = 6371; // Radius of the earth in km
                var dLat = ( lat2 - lat1 ).toRad();  // Javascript functions in radians
                var dLon = (lon2-lon1).toRad();
                var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) *
                    Math.sin(dLon/2) * Math.sin(dLon/2);
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
                var d = R * c; // Distance in km
                return d;
            }
        }
    }
</script>

<style lang="scss" scoped>
.pt-1{
    padding-top:1em;
}
@media screen and (min-width: 1024px) {
    .wrapper.wrapper--full.wrapper--grey {
        .wrapper {
            max-width: 80%;
        }
    }
}
</style>
