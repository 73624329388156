<template>
    <div style="min-height: 100vh; height: 100vh">
       <h4> Redirecting ...</h4>
    </div>
</template>

<script>

// Create a custom error class
    export default {
        name: "Shortlink",
        components: {
        },
        props: {
            slug: {
                type: String,
                required: true
            }
        },
        data: function () {
            return {
                regex: /^(\d+)$/,
            }
        },

        computed: {
            car() {
                return this.$store.state.ads.ad[this.slug];
            },
        },

        async serverPrefetch() {
            if (this.regex.test(this.$route.params.slug)) {
                await this.fetchSearchResults(this.slug);
                let res = await this.getAd();
                if(res.toString().startsWith('Error') || !res){
                    this.$store.state.ads.error404 = true;
                    return false;
                }
                this.$ssrContext.redirected = true;
                const make = this.car.make.replace(/\s+/g, '+').toLowerCase();
                const model = this.car.model.replace(/\s+/g, '+').toLowerCase();
                // link structure
                const link = '/used-cars/makes-' + make + '/models-' + model + '/r/' + this.car.slug;
                this.$ssrContext.redirect = link;
                console.log("Link", link);
                return res;
            }
        },

        mounted() {
            if (!this.car) {
                this.getAd();
            }
            if(this.$store.state.ads.error404){
                this.$router.push({ name: 'not-found' });
            }
        },

        methods: {
            async fetchSearchResults (url) {
                return await this.$store.dispatch('fetchSearchResults', url);
            },
            async getAd() {
                await this.$store.dispatch('ads/fetchAd', { slug: this.slug });
                if (!this.car || (typeof this.car.status !== 'undefined' && this.car.status == false)) {
                    return false;
                }
                // Return the actual ad when the condition is not met
                return this.car;
            },
        }
    }
</script>
